var app = angular.module('app', ['ngAnimate']);

app.controller('mainCtrl', function ($scope) {
$scope.boxes = [
{
    name: 'HypnoHIITS',
    image: 'images/hypnohiits.png',
    thumb: 'images/thumbs/wordpress.png',
    desc: 'Wordpress website with PODIA webshop integration',
    link: 'https://www.hypnohiits.com/' },    
{
    name: 'Careers Rest API integration - Vue JS',
    image: 'images/vue.png',
    thumb: 'images/thumbs/vue.png',
    desc: 'Vue JS Web App + RestAPI',
    link: 'http://vdg49356.dev.cogplatform.co.uk/careers/#careers' },
{ 
    name: 'radubanu.co.uk',
    image: 'images/thissite.png',
    thumb: 'images/thumbs/thissite.png',
    desc: 'Portfolio built with Angular 1, SCSS and HTML. Node.js, NPM & Gulp automated task runner.',
    link: 'http://www.radubanu.co.uk/' },
{
    name: 'Sinclair Group',
    image: 'images/sinclair.png',
    thumb: 'images/thumbs/sinclair.png',
    desc: 'A colection of 11 automotive, mobile first, responsive websites built with HTML5, SASS, JQuery, JavaScript',
    link: 'https://www.sinclairgroup.co.uk/' },
{
    name: '360 Product View',
    image: 'images/360.png',
    thumb: 'images/thumbs/360.png',
    desc: 'Exterior/Interior 360 View + Gallery for products served from AWS with serverless image and cloudfront',
    link: 'https://www.saxton4x4.co.uk/used-car-details/used-mercedes-benz-amg-gt-amg-gt-r-convertible--auto-7-gears-petrol/id-9610313121/' },
{
    name: 'Game Website Concept',
    image: 'images/xd.png',
    thumb: 'images/thumbs/xd.png',
    desc: 'Game website UI/UX Design created using Adobe XD',
    link: 'https://xd.adobe.com/view/3ab479ed-975b-444a-a7fc-a316fca45078-6fe7/?fullscreen' },
{
    name: 'Marshall Website Concept',
    image: 'images/marshall.png',
    thumb: 'images/thumbs/marshall.png',
    desc: 'Automotive website UI/UX Design created using Adobe Photoshop',
    link: 'https://www.blueskyinteractive.co.uk/concepts/marshall/home.aspx' },
];


$scope.selected = [];
$scope.selectBox = function (item, position) {
    $scope.selected = [{
    item: item,
    position: position }];

    $scope.$apply();
};
$scope.clearSelection = function () {
    $scope.selected = [];
};
});

app.directive('box', function () {
return {
    restrict: 'E',
    scope: {},
    bindToController: {
    onSelect: "=",
    item: "=" },

    controllerAs: 'box',
    controller: function () {
    var box = this;

    box.goFullscreen = function (e) {
        box.onSelect(box.item, e.target.getBoundingClientRect());
    };
    },
    link: function (scope, element) {
    element.bind('click', scope.box.goFullscreen);
    element.css({
        'background-image': 'url(' + scope.box.item.thumb + ')' });

    } };

});

app.directive('bigBox', function ($timeout) {
return {
    restrict: 'AE',
    scope: {},
    bindToController: {
    position: "=",
    selected: "=",
    onSelect: "=" },

    controllerAs: 'box',
    controller: function () {
    var box = this;
    },
    link: function (scope, element) {
    var css = {};
    for (var key in scope.box.position) {
        css[key] = scope.box.position[key] + 'px';
    }

    element.css(css);

    $timeout(function () {
        element.css({
        top: '50%',
        left: '10%' });

        element.addClass('image-out');
    }, 200);

    $timeout(function () {
        element.css({
        width: '80%',
        height: '100%' });

    }, 500);

    $timeout(function () {
        element.addClass('show');
    }, 800);
    } };

});